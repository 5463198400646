import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/regions");
  }
  get(id) {
    return axios.get(`/regions/${id}`);
  }
  create(data) {
    return axios.post("/regions", data);
  }
  update(id, data) {
    return axios.put(`/regions/${id}`, data);
  }
  delete(id) {
    return axios.delete(`/regions/${id}`);
  }
  deleteAll() {
    return axios.delete(`/regions`);
  }
  findByTitle(title) {
    return axios.get(`/regions?title=${title}`);
  }
  changeStatus(id) {
    return axios.get(`changeregionsStatus/${id}`);
  }
}
export default new DataService();